<template>
    <v-container fluid>
        <router-view></router-view>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'project-layout',
    data: () => ({}),
    components: {},
    created() {
        this.fetchProject();
    },
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['findProject']),
        fetchProject() {
            const id = this.project ? this.project.id : undefined;
            const idToGet = this.$route.params.project_id;

            if (idToGet !== id) {
                this.findProject(idToGet);
            }
        },
    },
};
</script>

<style></style>
